import EventSystem from 'https://cdn.venturz.co/modules/event-system/index.js';
import SystemDefinedUserStatusTracker from 'https://cdn.venturz.co/modules/system-defined-user-status-tracker/index.js';
import SelfDestructingMessage from 'https://cdn.venturz.co/modules/self-destructing-message/index.js';
import UserObserver from 'https://cdn.venturz.co/modules/user-observer/index.js';
import ScreenSizeDetector from 'https://cdn.venturz.co/modules/screen-size-detector/index.js';
import cookieReader from '/programs/cookie/reader.js';
import cookieWriter from '/programs/cookie/writer.js';
import dependencyNames from '/programs/constants/dependency-names.js';

import Indicators from './indicators.js';

window.Notifier = SelfDestructingMessage;

class Page {

  constructor(startup, user, pageSettings, configuration, functionalModules) {

    this.apiInterface = null;
    this.userObserver = null;
    this.eventSystem = null;
    this.tableOfContents = null;
    this.authorizationToken = null;
    this.user = user;

    this.userInitialized = false;
    this.startup = startup;
    this.pageSettings = pageSettings;
    this.configuration = configuration;
    this.functionalModules = functionalModules;
    this.url = new URL(window.location.href);
    this.indicators = new Indicators();
    this.screenSizeDetector = new ScreenSizeDetector();

    this.dependencies = {};
    this.cssDependencies = {};

    this.moduleInstances = [];

    window.page = this;

  }

  async getDependency(name) {

    const dependency = this.dependencies[name];

    if (dependency !== void 0) {
      return dependency;
    }

    if (name === dependencyNames.API_INTERFACE) {

      await this.loadAndInitializeAPIInterface();

    }

    if (name === dependencyNames.DIALOGS_MANAGER) {

      await this.loadAndInitializeDialogsManager();

    }

    return this.dependencies[name];

  }

  async initializeModule(functionalModule) {

    const initializerId = `${performance.now()}-${Math.random()}-${functionalModule.type}`;

    try {

      const Module = (await import(`/programs/${functionalModule.type}/index.js`)).default;

      const options = {
        ...functionalModule.options,
        ...functionalModule.properties || {},
        options: functionalModule.options
      };
      const moduleInstance = new Module(options, this);

      this.moduleInstances.push(moduleInstance);

      await moduleInstance.initialize();

    } catch (exception) {

      // eslint-disable-next-line no-console
      console.log(exception);

    }

  }

  async initializeBlockLevelModules() {

    const functionalModules = this.functionalModules;

    const promises = [];

    for (let functionalModule of functionalModules) {

      const promise = this.initializeModule(functionalModule);

      promises.push(promise);

    }

    return Promise.all(promises);

  }

  async tryInitializeTableOfContents() {

    if (this.pageSettings.enableTableOfContents === false) {
      return;
    }

    const TableOfContents = (await import('/programs/table-of-contents/index.js')).default;

    this.tableOfContents = new TableOfContents(this);

    this.tableOfContents.initialize();

  }

  async initializePageLevelModules() {

    await this.tryInitializeTableOfContents();

  }

  async tryInitializeReception() {

    if (this.pageSettings.enableReception === false) {
      return;
    }
    console.log(this.eventSystem);

    try {

      // eslint-disable-next-line no-undef
      await initializeReception(this);

    } catch (exception) {

      console.log(exception);

    }

  }

  async initializeModules() {

    await this.initializeBlockLevelModules();
    await this.initializePageLevelModules();
    await this.tryInitializeReception();

  }

  async loadAndInitializeAPIInterface() {

    const APIInterface = (await import('/programs/api-interface/index.js')).default;

    this.dependencies[dependencyNames.API_INTERFACE] = this.initializeAPIInterface(APIInterface);

  }

  async loadAndInitializeDialogsManager() {

    const DialogsManager = (await import('/programs/dialogs-manager/index.js')).default;

    this.dependencies[dependencyNames.DIALOGS_MANAGER] = new DialogsManager(this);

  }

  initializeAPIInterface(APIInterface) {

    const startupId = this.startup._id;
    const apiURL = this.configuration.apiURL;

    return new APIInterface(startupId, apiURL, this.getAuthorizationToken());

  }

  initializeContext() {

    this.authorizationToken = cookieReader.get('authorizationToken');

  }

  async initializeUserObserver() {

    this.userObserver = new UserObserver(true);

    await this.userObserver.start();

    window.dispatchEvent(new CustomEvent('UserObserverInitialized', {
      observerId: this.userObserver.getObserverId()
    }));

  }

  handleServerEvent(event) {

    this.eventSystem.internal.emit(event.name, event.payload);

  }

  doInitializeEventSystem(authorizationToken, callback) {

    const apiAddress = this.configuration.apiAddress;
    const wsAPIProtocol = apiAddress.wsAPIProtocol;
    const apiHost = apiAddress.host;
    const apiPort = apiAddress.port;
    const version = apiAddress.version;

    const url = `${wsAPIProtocol}://${apiHost}:${apiPort}/websocket`;
    const onReady = callback;
    // eslint-disable-next-line func-style
    const onConnected = function onConnected() {};
    // eslint-disable-next-line func-style
    const onDisconnected = function onDisconnected() {};
    const onEvent = this.handleServerEvent.bind(this);
    // eslint-disable-next-line func-style
    const onAction = function onAction() {};
    // eslint-disable-next-line func-style
    const onServerCallWhenOffline = function onServerCallWhenOffline() {};

    this.eventSystem.initializeWebSocketClient(url, version, authorizationToken, onReady, onConnected, onDisconnected, onEvent, onAction, onServerCallWhenOffline);

  }

  async initializeEventSystem(authorizationToken) {

    return new Promise(function resolver(resolve) {

      this.doInitializeEventSystem(authorizationToken, resolve);

    }.bind(this));

  }

  async connect() {

    const authorizationToken = this.getAuthorizationToken() || '';

    this.eventSystem = new EventSystem();
    await this.initializeEventSystem(authorizationToken);
    await this.eventSystem.tryAssociateWithObserverId({
      authorizationToken,
      observerId: this.userObserver.getObserverId()
    });

  }

  async tryInitializeUser() {

    if (this.userInitialized === true) {
      return this.user;
    }

    const apiInterface = await this.getDependency(dependencyNames.API_INTERFACE);

    try {

      this.user = await apiInterface.getUserByToken();

      await apiInterface.initializeUserInterfaces(this.user);

    } catch (exception) {

      this.user = null;

    } finally {
      this.userInitialized = true;
    }

    return this.user;

  }

  async tryInitializeOnlineStatusTracker() {

    this.onlineStatusTracker = new SystemDefinedUserStatusTracker();

    if (this.user === null) {
      this.onlineStatusTracker.initializeWithObserverId(this.configuration.apiURL, this.startup._id, this.userObserver.getObserverId());
    } else {
      this.onlineStatusTracker.initialize(this.configuration.apiURL, this.startup._id, this.user._id, this.getAuthorizationToken());
    }

  }

  async initialize() {

    this.initializeContext();
    this.initializeUserObserver();
    this.connect();
    await this.initializeModules();
    await this.tryInitializeOnlineStatusTracker();

  }

  async importCSS(path) {

    if (this.cssDependencies[path] !== void 0) {
      return;
    }

    this.cssDependencies[path] = true;

    const link = document.createElement('link');

    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('href', path);

    document.head.appendChild(link);

  }

  getUserObserverId() {

    return this.userObserver.getObserverId();

  }

  getPageSettings() {

    return this.pageSettings;

  }

  setAuthorizationToken(authorizationToken) {

    this.authorizationToken = authorizationToken;

  }

  async setAuthorization(authorization) {

    const token = authorization.token;

    cookieWriter.set('authorizationToken', token, 30);

    this.setAuthorizationToken(token);

    await this.getAPIInterface().setAuthorization(authorization);

  }

  getUserId() {

    return cookieReader.get('userId');

  }

  getAuthorizationToken() {

    return cookieReader.get('authorizationToken');

  }

  getMainContentContainer() {

    return document.getElementById('main-content-container');

  }

  getPageExtrasContainer() {

    return document.getElementById('page-extras-container');

  }

  getDialogsManager() {

    return this.dependencies[dependencyNames.DIALOGS_MANAGER];

  }

  getAPIInterface() {

    return this.dependencies[dependencyNames.API_INTERFACE];

  }

  getStartup() {

    return this.startup;

  }

  getAuthenticatorAddress() {

    const host = this.startup.address.host;

    if (host.indexOf('localhost') !== -1) {
      return 'http://auth.local.com:3002';
    } else if (host.indexOf('development') !== -1) {
      return 'https://auth.development.venturz.co';
    } else {
      return 'https://auth.venturz.co';
    }

  }

  async reassociateEventSystem() {

    const authorizationToken = this.getAuthorizationToken();

    await this.eventSystem.tryAssociateWithObserverId({
      authorizationToken,
      observerId: this.userObserver.getObserverId()
    });

  }

  deleteQueryParameters(names) {

    for (let name of names) {
      this.url.searchParams.delete(name);
    }

    window.history.replaceState({}, '', this.url);

  }

  async getAssistantClass() {

    const Assistant = (await import('../assistant/index.js')).default;

    return Assistant;

  }

}

window.Page = Page;

export default Page;