import { el, mount, unmount } from 'https://cdn.venturz.co/lib/redom.es.js';

class Indicators {

  showFullPageSpinner() {

    const spinner = el('#full-page-spinner', [
      el('img', {
        src: 'https://shared-assets.venturz.co/images/spinner.svg',
        width: 25,
        height: 25
      })
    ]);

    document.body.appendChild(spinner);

  }

  hideFullPageSpinner() {

    const spinner = document.getElementById('full-page-spinner');

    if (spinner === null) {
      return;
    }

    document.body.removeChild(spinner);

  }

  showFullPageActionProgressBar(activities, rotationInterval) {

    this.actionProgressBar = new ActionProgressBar(activities, rotationInterval);

    mount(document.body, this.actionProgressBar);

  }

  hideFullPageActionProgressBar() {

    unmount(document.body, this.actionProgressBar);

    this.actionProgressBar = null;

  }

}

class ActionProgressBar {

  constructor(activities, rotationInterval = 2000) {

    this.activityIndex = -1;
    this.activities = activities;
    this.rotationInterval = rotationInterval;
    this.activity = el('label', activities[this.activityIndex]);
    this.spinner = el('img', {
      src: 'https://shared-assets.venturz.co/images/spinner.svg',
      width: 25,
      height: 25
    });
    this.container = el('div', { className: 'action-progress-bar' }, [this.spinner, this.activity]);

    this.el = el('#full-page-action-progress-bar', this.container);

  }

  rotateActivities() {

    this.activityIndex += 1;

    if (this.activityIndex >= this.activities.length) {
      this.activityIndex = 0;
    }

    this.activity.textContent = this.activities[this.activityIndex];

    setTimeout(this.rotateActivities.bind(this), this.rotationInterval);

  }

  onmount() {

    this.rotateActivities();

  }

}

export default Indicators;